import React from "react";
import { Helmet } from 'react-helmet-async';
import { config } from './config'

export const SEOHeaders = ({title = config.meta.title, appendTitle = false, description = config.meta.description, name = '', type = ''}) => {
  return <Helmet>
    { /* Standard metadata tags */ }
    <title>{title + (appendTitle ? config.meta.append : '')}</title>
    <meta name='description' content={description} />
    { /* End standard metadata tags */ }
    { /* Facebook tags */ }
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title + appendTitle ? config.meta.append : ''} />
    <meta property="og:description" content={description} />
    { /* End Facebook tags */ }
    { /* Twitter tags */ }
    <meta name="twitter:creator" content={name} />
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title + appendTitle ? config.meta.append : ''} />
    <meta name="twitter:description" content={description} />
    { /* End Twitter tags */ }
  </Helmet>
}