import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as serviceWorker from './serviceWorker';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import Landing from './Landing';

import './index.scss';

const router = createBrowserRouter([{
  path: "/",
  element: <Landing/>
}]);

const root = ReactDOM.createRoot(document.getElementById('root')  as HTMLElement);
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
