export const config = {
  app: {
    apiUri: process.env.REACT_APP_API_URI || 'http://localhost:4000',
    wsUri: process.env.REACT_APP_WS_URI || 'ws://localhost:4000',
  },
  stripe: {
    stripeKey: process.env.REACT_APP_STRIPE_KEY || 'pk_test_51Ok253D3wDSCvvQ351vk6C6F24k54cxA2i1vjxMu1t3u1Kxbs418rNBE9l8U8JpZzxKYB4iTBQnqxtbj0i2IgOCk00CPiMpczJ'
  },
  meta: {
    append: ' - Aurore.ai',
    title: 'Aurore.ai - Gaming and Working with company',
    description: "Gaming and Working with company. Imagine a friend who's always there to chat, strategize, and keep you company, making every moment more engaging and tasks less tedious. Whether you're battling enemies in your favorite game or crunching numbers for work."
  }
}