import React, { Component } from 'react'
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom'
import {
  Bars3Icon,
  XMarkIcon,
  CheckIcon,
  VideoCameraIcon,
  AcademicCapIcon,
  AdjustmentsHorizontalIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/20/solid'
import { Dialog } from '@headlessui/react'

import { SEOHeaders } from "./SeoHeaders"

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface LandingProps {
  navigate: any
  params: any
  searchParams: any
}

type LandingStates = {
  mobileMenuOpen: boolean
}

class Landing extends Component <LandingProps, LandingStates> {
  constructor (props: LandingProps) {
    super(props)
    this.state = {
      mobileMenuOpen: false
    }
  }

  render () {
    return (
      <div className="bg-gray-900" id="top">
        <SEOHeaders/>
        <main>

          {/* Header */}
          <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
              <div className="flex lg:flex-1">
                <Link to="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Aurore.ai</span>
                  <img
                    className="h-8 w-auto"
                    src="/logo.png"
                    alt=""
                  />
                </Link>
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-300"
                  onClick={() => this.setState({mobileMenuOpen: true})}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item: any) => (
                  <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-300">
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                <a href="/#download" className="text-sm font-semibold leading-6 text-white">
                  Get started <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={this.state.mobileMenuOpen} onClose={() => this.setState({mobileMenuOpen: false})}>
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                  <a href="/#download" className="-m-1.5 p-1.5">
                    <span className="sr-only">Aurore.ai</span>
                    <img
                      className="h-8 w-auto"
                      src="/logo.png"
                      alt=""
                    />
                  </a>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => this.setState({mobileMenuOpen: false})}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      {navigation.map((item: any) => (
                        <a
                          onClick={()=>this.setState({mobileMenuOpen: false})}
                          key={item.name}
                          href={item.href}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="py-6">
                      <a
                        onClick={()=>this.setState({mobileMenuOpen: false})}
                        href="/#download"
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>

          {/* Hero section */}
          <div className="relative isolate overflow-hidden">
            <div
              className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
              aria-hidden="true"
            >
              <div
                className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                style={{
                  clipPath:
                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                }}
              />
            </div>
            <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-40">
              <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                <div className="mt-24 sm:mt-32 lg:mt-16">
                  <a href="#" className="inline-flex space-x-6">
                    <span className="rounded-full bg-red-500/10 px-3 py-1 text-sm font-semibold leading-6 text-red-400 ring-1 ring-inset ring-red-500/20">
                      Early access
                    </span>
                  </a>
                </div>
                <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  Gaming and Working with companion
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Imagine a friend who's always there to chat, strategize, and keep you company, making every moment more engaging and tasks less tedious. Whether you're battling enemies in your favorite game or crunching numbers for work.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/#download"
                    className="rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
                  >
                    Get started
                  </a>
                </div>
              </div>
              <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                  {/*
                  <img
                    src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                    alt="App screenshot"
                    width={2432}
                    height={1442}
                    className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                  />
                  */}
                  <video
                    src='/aurore-video.mp4'
                    muted
                    autoPlay
                    loop
                    width={1280}
                    height={720}
                    className="w-[76rem] rounded-md shadow-2xl border-b-8 border-t-4 border-l-4 border-r-4 border-slate-500/30"
                  />
                </div>
              </div>
            </div>
          </div>

          {/*
          <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-16 lg:px-8">
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/transistor-logo-white.svg"
                alt="Transistor"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/reform-logo-white.svg"
                alt="Reform"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg"
                alt="Tuple"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg"
                alt="SavvyCal"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg"
                alt="Statamic"
                width={158}
                height={48}
              />
            </div>
          </div>
          */}

          {/* Feature section */}
          <div className="mx-auto pt-12 mb-32 max-w-7xl px-6 sm:pt-12 lg:px-8" id="features">
            <div className="mx-auto max-w-2xl text-center">
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Chat about what you're doing
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Chatting should't be separate experience from what you usually do. Aurore will join you on your path of doing anything you set to do.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {primaryFeatures.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="text-base font-semibold leading-7 text-white flex items-center flex-col">
                      <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-red-500">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <div>{feature.name}</div>
                    </dt>
                    <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                      <p className="flex-auto text-center">{feature.description}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* Pricing section */}
          <div className="mx-auto pt-16 max-w-7xl px-6 sm:pt-32 lg:px-8" id="pricing">
            <div className="mx-auto max-w-4xl text-center">
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-3xl">
                Pricing options
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
              Aurore has currently one option with another coming in following months. Your balance can also be reloaded by activating ADs thanks to partnership with <a href="https://chatady.com">ChatADy</a>
            </p>
            <div className="mt-10 gap-8 lg:mx-0 flex flex-col md:flex-row justify-center items-center">
              {tiers.map((tier)=>{
                return <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'ring-2 ring-red-500' : 'ring-1 ring-white/10',
                    'rounded-3xl p-8 xl:p-10 w-full md:w-1/3',
                  )}
                >
                  <div className="flex items-center justify-between gap-x-4">
                    <h2 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                      {tier.name}
                    </h2>
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                  <p className='text-white text-4xl pt-4'>
                    {tier.price}
                    <span className='text-gray-300 text-sm pl-2'>per interaction</span>
                  </p>
                  <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-white" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              })}
            </div>
          </div>
                
          {/* CTA section */}
          <div className="relative isolate mt-32 px-6 py-32 sm:mt-32 sm:py-40 lg:px-8">
            <svg
              className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="1d4240dd-898f-445f-932d-e2872fd12de3"
                  width={200}
                  height={200}
                  x="50%"
                  y={0}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={0} className="overflow-visible fill-gray-800/20">
                <path
                  d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
            </svg>
            <div
              className="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1108/300] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                style={{
                  clipPath:
                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                }}
              />
            </div>
            <div className="mx-auto max-w-2xl text-center" id="download">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Download
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                Start enjoying company of Aurore at anything you do.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  download
                  href="https://www.dropbox.com/scl/fi/8dtvl9fd668kkwzqmg3u9/Aurore-mac-x64.dmg?rlkey=63hau2vapvdxm8k78vm158ipu&st=2zhg5k4p&dl=1"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Mac (x64)
                </a>
                <a
                  download
                  href="https://www.dropbox.com/scl/fi/zzw3rn7z03gfvoq58y7bk/Aurore-mac-arm64.dmg?rlkey=1w4e3f1va8pkvlhl6oimf09un&st=fvt8gub7&dl=1"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Mac (ARM-M1)
                </a>
                <a
                  download
                  href="https://www.dropbox.com/scl/fi/hhn5ggn6g8e7k63a6tw9a/Aurore-linux-amd64.deb?rlkey=yhd65d6hpf48t1dy7rpgytele&st=o6vxpvr4&dl=1"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Linux (x64)
                </a>
                <a
                  download
                  href="https://www.dropbox.com/scl/fi/3y0wozodss67ppkfm7qrd/Aurore-win-x64.exe?rlkey=39sd6n1wv0fh9q0o7e7ejyx5n&st=cvckohn0&dl=1"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Win (x64)
                </a>
              </div>
              
              
              <div className="rounded-md bg-yellow-50 p-4 mt-12 opacity-90">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                      We are in the process of getting Aurore signed and properly authenticated, so you may see notices upon first launch. Aurore also needs access to your microphone and screen, so please ensure you grant the necessary permissions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              
              
            </div>
          </div>
        </main>

        {/* Footer */}
        <footer aria-labelledby="footer-heading" className="relative">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
            <div className="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
              <div className="flex space-x-6 md:order-2">
                {footerNavigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
              <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
                &copy; 2024 ARCLOOP LIMITED. All rights reserved.<br/>
                <a href="/termsandconditions.html">Terms and Conditions</a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default function LandingWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  const searchParams = useSearchParams()
  return <Landing params={params} navigate={navigate} searchParams={searchParams}/>
}

const navigation = [
  { name: 'Top', href: '#top' },
  { name: 'Features', href: '#features' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Download', href: '#download' },
]

const primaryFeatures = [
  {
    name: 'See and Hear',
    description:
      'Aurore is able to hear you and see the computer screen',
    href: '#',
    icon: VideoCameraIcon,
  },
  {
    name: 'Cutting Edge AI',
    description:
      'Using the latest advancements in AI bringing the knowledge to help you achieve anything',
    href: '#',
    icon: AcademicCapIcon,
  },
  {
    name: 'Cutomize Experience',
    description:
      'Customize Aurore to your liking. Switch her visual style, soon also her voice',
    href: '#',
    icon: AdjustmentsHorizontalIcon,
  }
]

const tiers = [{
  name: 'Aurore',
  id: 'tier-aurore',
  href: '#',
  price: '$0.002',
  description: 'Engage with your companion Aurore on any topic, with an average cost of just',
  features: [
    'Affordable pricing',
    'Near real-time voice',
    'Screen vision capabilities'
  ],
  mostPopular: true,
}, {
  name: 'Upcoming...',
  id: 'tier-premium',
  href: '#',
  price: '$0.03',
  description: 'Aurore with high resolution vision and state of art reasoning with average cost of',
  features: [
    'High definition vision',
    'State of art reasoning'
  ],
  mostPopular: false,
}]

const footerNavigation = {
  social: [
    {
      name: 'X',
      href: 'https://x.com/Aurore__ai',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    }
  ],
}